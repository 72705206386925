import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import subscriptionCard from '../../assets/images/home/subscription-card.png';
import FirebaseService from '../../core/services/firebase/firebaseService';
import { useAppSelector } from '../../core/services/hooks';
import FirebaseKey from '../FirebaseKey/FirebaseKey';

export function UpgradeBanner() {
  const navigate = useNavigate();
  const getUserInfoState = useAppSelector((state) => state.user.getUserInfo);
  const activeSubs = getUserInfoState.data?.activeSubs;
  const activeSub =
    getUserInfoState.data?.activeSubs &&
    getUserInfoState.data?.activeSubs.length > 0
      ? getUserInfoState.data?.activeSubs[0]
      : undefined;
  const cancelled = activeSub?.cancelledOn;
  const isUpgradable = activeSub?.isUpgradable;
  const showUpgradeBanner =
    activeSubs && activeSubs.length > 0 && !cancelled && isUpgradable;
  if (activeSubs && activeSubs.length > 0 && !showUpgradeBanner) {
    return null;
  }

  return (
    <div className="relative flex gap-1 overflow-hidden rounded-md bg-[#002C40] p-4">
      <img
        src={subscriptionCard}
        className="absolute inset-0 h-full w-full object-cover object-center"
        alt=""
      />
      <div className="z-10 flex w-32 grow flex-col gap-1">
        <div className="break-word text-white">
          {isUpgradable ? (
            <FirebaseKey
              firebaseKey={FirebaseService.hamburger_upgrade_header}
            />
          ) : (
            <FirebaseKey
              firebaseKey={FirebaseService.hamburger_abonelik_abone_header}
            />
          )}
        </div>
        <div className="flex break-word text-white/50">
          {isUpgradable ? (
            <FirebaseKey firebaseKey={FirebaseService.hamburger_upgrade_desc} />
          ) : (
            <FirebaseKey
              firebaseKey={FirebaseService.hamburger_abonelik_aboneolmayan_desc}
            />
          )}
        </div>
      </div>
      <div className="z-10 flex w-20 grow items-end">
        <Button
          onClick={() => navigate('/subscription')}
          className="overflow-hidden bg-[#00C5FF] hover:text-black"
        >
          {isUpgradable ? (
            <FirebaseKey
              firebaseKey={FirebaseService.hamburger_upgrade_buton}
            />
          ) : (
            <FirebaseKey
              firebaseKey={
                FirebaseService.hamburger_abonelik_aboneolmayan_buton
              }
            />
          )}
        </Button>
      </div>
    </div>
  );
}
