import { getAnalytics, logEvent } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import {
  fetchAndActivate,
  getRemoteConfig,
  getValue,
} from 'firebase/remote-config';
import { isEmpty } from 'lodash';
import Helpers from '../../utilities/helpers';

export default class FirebaseService {
  private static readonly config = {
    apiKey: `${process.env.REACT_APP_FIREBASE_API_KEY}`,
    authDomain: `${process.env.REACT_APP_FIREBASE_AUTH_DOMAIN}`,
    databaseURL: `${process.env.REACT_APP_FIREBASE_DATABASE_URL}`,
    projectId: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}`,
    storageBucket: `${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}`,
    messagingSenderId: `${process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID}`,
    appId: `${process.env.REACT_APP_FIREBASE_APP_ID}`,
    measurementId: `${process.env.REACT_APP_FIREBASE_MEASUREMENT_ID}`,
  };

  private static readonly app = initializeApp(FirebaseService.config);
  private static readonly remoteConfig = getRemoteConfig(FirebaseService.app);
  private static readonly analytics = getAnalytics(FirebaseService.app);
  private static readonly separator = '|';

  constructor() {
    FirebaseService.remoteConfig.defaultConfig = {
      test_key: 'Firebase test',
    };
  }

  public async fetchAndActivateRemoteConfig(): Promise<void> {
    await fetchAndActivate(FirebaseService.remoteConfig);
  }

  public static getValue(key: string): string {
    const value = getValue(FirebaseService.remoteConfig, key).asString();
    return isEmpty(value) ? key : value;
  }

  public static logEvent(
    key: string,
    params?: { [key: string]: unknown }
  ): void {
    if (!Helpers.isEnvProd()) {
      console.log(`>> EVENT: ${key}`);
      return;
    }
    logEvent(FirebaseService.analytics, key, params);
  }

  public static getBannerImages(): string[] {
    return this.getStringArray(FirebaseService.banner_images);
  }

  public static getStringArray(key: string): string[] {
    const mergedString = FirebaseService.getValue(key);
    if (!mergedString) return [];
    return mergedString
      .split(FirebaseService.separator)
      .filter((s) => s.trim());
  }

  // KEYS
  public static readonly banner_images = 'banner_images';
  public static readonly login_header = 'login_header';
  public static readonly login_description = 'login_description';
  public static readonly login_msisdn_header = 'login_msisdn_header';
  public static readonly login_msisdn_placeholder = 'login_msisdn_placeholder';
  public static readonly login_buton = 'login_buton';
  public static readonly login_otp_header = 'login_otp_header';
  public static readonly login_otp_description = 'login_otp_description';
  public static readonly login_otp_description_text =
    'login_otp_description_text';
  public static readonly login_otp_placeholder = 'login_otp_placeholder';
  public static readonly login_otp_buton = 'login_otp_buton';
  public static readonly signup_header = 'signup_header';
  public static readonly signup_description = 'signup_description';
  public static readonly signup_otp_description = 'signup_otp_description';
  public static readonly signup_name = 'signup_name';
  public static readonly signup_name_placeholder = 'signup_name_placeholder';
  public static readonly signup_surname = 'signup_surname';
  public static readonly signup_surname_placeholder =
    'signup_surname_placeholder';
  public static readonly signup_etk_text = 'signup_etk_text';
  public static readonly signup_kvkk_text = 'signup_kvkk_text';

  public static readonly signup_rumuz_page_title = 'signup_rumuz_page_title';
  public static readonly signup_rumuz_page_header = 'signup_rumuz_page_header';
  public static readonly signup_rumuz_page_button = 'signup_rumuz_page_button';
  public static readonly signup_rumuz_unique_warning =
    'signup_rumuz_unique_warning';
  public static readonly signup_rumuz_yasak_kelime_warning =
    'signup_rumuz_yasak_kelime_warning';
  public static readonly signup_rumuz_title = 'signup_rumuz_title';
  public static readonly signup_rumuz_placeholder = 'signup_rumuz_placeholder';
  public static readonly signup_rumuz_info = 'signup_rumuz_info';

  public static readonly welcome_header = 'welcome_header';
  public static readonly welcome_text_description = 'welcome_text_description';
  public static readonly country_select_buton = 'country_select_buton';
  public static readonly club_select_buton = 'club_select_buton';
  public static readonly signup_choose_team_placeholder =
    'signup_choose_team_placeholder';
  public static readonly subscribe_buton = 'subscribe_buton';

  public static readonly upgrade_vip_header = 'hamburger_abonelik_abone_header';
  public static readonly upgrade_vip_description =
    'hamburger_abonelik_abone_desc';
  public static readonly upgrade_vip_button = 'hamburger_abonelik_abone_buton';
  public static readonly buy_vip_header =
    'hamburger_abonelik_aboneolmayan_header';
  public static readonly buy_vip_description =
    'hamburger_abonelik_aboneolmayan_desc';
  public static readonly buy_vip_button =
    'hamburger_abonelik_aboneolmayan_buton';

  public static readonly hamburger_puan_kullan_buton =
    'hamburger_puan_kullan_buton';
  public static readonly hamburger_anket_buton = 'hamburger_anket_buton';
  public static readonly hamburger_oyun_buton = 'hamburger_oyun_buton';
  public static readonly hamburger_cark_buton = 'hamburger_cark_buton';
  public static readonly hamburger_profil_buton = 'hamburger_profil_buton';
  public static readonly hamburger_qr_buton = 'hamburger_qr_buton';
  public static readonly hamburger_anasayfa_buton = 'hamburger_anasayfa_buton';
  public static readonly hamburger_takimlar = 'hamburger_takimlar';
  public static readonly hamburger_plus_puan = 'hamburger_plus_puan';
  public static readonly hamburger_plus_lig = 'hamburger_plus_lig';
  public static readonly hamburger_abonelik = 'hamburger_abonelik';
  public static readonly hamburger_duyurular = 'hamburger_duyurular';
  public static readonly hamburger_plus_faydalar = 'hamburger_plus_faydalar';
  public static readonly member_hamburger_abone = 'member_hamburger_abone';
  public static readonly freesubs_hamburger_aboneol =
    'freesubs_hamburger_aboneol';
  public static readonly hamburger_upgrade_header = 'hamburger_upgrade_header';
  public static readonly hamburger_upgrade_desc = 'hamburger_upgrade_desc';
  public static readonly hamburger_upgrade_buton = 'hamburger_upgrade_buton';
  public static readonly hamburger_abonelik_abone_header =
    'hamburger_abonelik_abone_header';
  public static readonly hamburger_abonelik_aboneolmayan_desc =
    'hamburger_abonelik_aboneolmayan_desc';
  public static readonly hamburger_abonelik_aboneolmayan_buton =
    'hamburger_abonelik_aboneolmayan_buton';
  public static readonly membership_type_header = 'membership_type_header';
  public static readonly membership_type_description =
    'membership_type_description';
  public static readonly membership_type_annual = 'membership_type_annual';
  public static readonly membership_type_monthly = 'membership_type_monthly';
  public static readonly membership_type_weekly = 'membership_type_weekly';
  public static readonly card_type_more_buton = 'card_type_more_buton';
  public static readonly card_type_select_buton = 'card_type_select_buton';
  public static readonly paid_subscription_step2 = 'paid_subscription_step2';
  public static readonly paid_subscription_step3 = 'paid_subscription_step3';

  public static readonly select_premium_kart_continue_button =
    'select_premium_kart_continue_button';
  public static readonly select_premium_kart_best = 'select_premium_kart_best';

  public static readonly select_payment_header = 'select_payment_header';
  public static readonly select_payment_description =
    'select_payment_description';
  public static readonly select_payment_kart_header =
    'select_payment_kart_header';
  public static readonly select_payment_kart_buton =
    'select_payment_kart_buton';
  public static readonly select_payment_mobil_header =
    'select_payment_mobil_header';
  public static readonly select_payment_mobil_buton =
    'select_payment_mobil_buton';

  public static readonly contact_us_header = 'contact_us_header';
  public static readonly contact_us_description = 'contact_us_description';
  public static readonly contact_us_name_header = 'contact_us_name_header';
  public static readonly contact_us_name_description =
    'contact_us_name_description';
  public static readonly contact_us_surname_header =
    'contact_us_surname_header';
  public static readonly contact_us_surname_description =
    'contact_us_surname_description';
  public static readonly contact_us_email_header = 'contact_us_email_header';
  public static readonly contact_us_email_description =
    'contact_us_email_description';
  public static readonly contact_us_number_header = 'contact_us_number_header';
  public static readonly contact_us_number_placeholder =
    'contact_us_number_placeholder';
  public static readonly contact_us_topic_header = 'contact_us_topic_header';
  public static readonly contact_us_topic_placeholder =
    'contact_us_topic_placeholder';
  public static readonly contact_us_topic_titles = 'contact_us_topic_titles';
  public static readonly contact_us_message_header =
    'contact_us_message_header';
  public static readonly contact_us_message_description =
    'contact_us_message_description';
  public static readonly contact_us_message_send_buton =
    'contact_us_message_send_buton';
  public static readonly contact_us_popup_header = 'contact_us_popup_header';
  public static readonly contact_us_popup_description =
    'contact_us_popup_description';
  public static readonly contact_us_popup_buton = 'contact_us_popup_buton';

  public static readonly select_payment_mobile_header =
    'select_payment_mobile_header';
  public static readonly select_payment_mobile_description =
    'select_payment_mobile_description';
  public static readonly select_payment_mobile_info_header =
    'select_payment_mobile_info_header';
  public static readonly select_payment_mobile_info_description =
    'select_payment_mobile_info_description';
  public static readonly select_payment_mobile_info_step_header =
    'select_payment_mobile_info_step_header';
  public static readonly select_payment_mobile_info_steps =
    'select_payment_mobile_info_steps';
  public static readonly select_payment_mobile_message =
    'select_payment_mobile_message';
  public static readonly select_payment_mobile_message_sent =
    'select_payment_mobile_message_sent';

  public static readonly sss_header = 'sss_header';
  public static readonly sss_description = 'sss_description';
  public static readonly seacrh_buton = 'seacrh_buton';
  public static readonly sss_question_lists = 'sss_question_lists';
  public static readonly sss_answer_lists = 'sss_answer_lists';

  public static readonly membership_welcome_header =
    'membership_welcome_header';
  public static readonly membership_welcome_description =
    'membership_welcome_description';
  public static readonly membership_advantages_info1 =
    'membership_advantages_info1';
  public static readonly membership_advantages_info2 =
    'membership_advantages_info2';
  public static readonly membership_welcome_poll = 'membership_welcome_poll';
  public static readonly membership_welcome_home = 'membership_welcome_home';

  public static readonly payment_mobil_error_header =
    'payment_mobil_error_header';
  public static readonly payment_mobil_error_description =
    'payment_mobil_error_description';
  public static readonly payment_mobil_error_info_header =
    'payment_mobil_error_info_header';
  public static readonly payment_mobil_error_info_description =
    'payment_mobil_error_info_description';
  public static readonly payment_mobil_error_try_buton =
    'payment_mobil_error_try_buton';
  public static readonly payment_mobil_error_kart_buton =
    'payment_mobil_error_kart_buton';

  public static readonly payment_kart_error_header =
    'payment_kart_error_header';
  public static readonly payment_kart_error_description =
    'payment_kart_error_description';
  public static readonly payment_kart_error_info_header =
    'payment_kart_error_info_header';
  public static readonly payment_kart_error_info_description =
    'payment_kart_error_info_description';
  public static readonly payment_kart_error_retry_buton =
    'payment_kart_error_retry_buton';
  public static readonly payment_kart_error_mobil_buton =
    'payment_kart_error_mobil_buton';

  public static readonly footer_homepage_tab = 'footer_homepage_tab';
  public static readonly footer_contact_tab = 'footer_contact_tab';
  public static readonly footer_faq_tab = 'footer_faq_tab';

  public static readonly home_coin_title = 'home_coin_title';
  public static readonly home_coin_desc = 'home_coin_desc';
  public static readonly home_coin_button = 'home_coin_button';
  public static readonly home_point_title = 'home_point_title';
  public static readonly home_point_desc = 'home_point_desc';
  public static readonly home_point_button = 'home_point_button';
  public static readonly home_wheel_title = 'home_wheel_title';
  public static readonly home_wheel_desc = 'home_wheel_desc';
  public static readonly home_wheel_button = 'home_wheel_button';

  public static readonly chat_title = 'chat_title';
  public static readonly chat_team = 'chat_team';
  public static readonly chat_all = 'chat_all';
  public static readonly refresh_chat = 'refresh_chat';
  public static readonly new_message = 'new_message';
  public static readonly chat_input_limit_text = 'chat_input_limit_text';
  public static readonly chat_banned_word_title = 'chat_banned_word_title';
  public static readonly chat_banned_word_desc = 'chat_banned_word_desc';
  public static readonly chat_banned_word_button = 'chat_banned_word_button';
  public static readonly sohbet_pin_title = 'sohbet_pin_title';
  public static readonly sohbet_pin_desc = 'sohbet_pin_desc';
  public static readonly team_chat_warning = 'team_chat_warning';
  public static readonly general_chat_warning = 'general_chat_warning';

  public static readonly live_match_header = 'live_match_header';
  public static readonly past_tab = 'past_tab';
  public static readonly today_tab = 'today_tab';
  public static readonly next_tab = 'next_tab';
  public static readonly live_match_notexist_header =
    'live_match_notexist_header';
  public static readonly live_match_notexist_desc = 'live_match_notexist_desc';
  public static readonly past_match_notexist_header =
    'past_match_notexist_header';
  public static readonly past_match_notexist_desc = 'past_match_notexist_desc';
  public static readonly next_match_notexist_header =
    'next_match_notexist_header';
  public static readonly next_match_notexist_desc = 'next_match_notexist_desc';
  public static readonly penalties = 'penalties';

  public static readonly landing_mail_title = 'landing_mail_title';
  public static readonly landing_mail_description = 'landing_mail_description';
  public static readonly landing_mail_placeholder = 'landing_mail_placeholder';
  public static readonly landing_mail_button = 'landing_mail_button';

  public static readonly leaderboard_header = 'leaderboard_header';
  public static readonly leaderboard_title = 'leaderboard_title';
  public static readonly leaderboard_team = 'leaderboard_team';
  public static readonly leaderboard_all = 'leaderboard_all';
  public static readonly leaderboard_all_teams = 'leaderboard_all_teams';
  public static readonly leaderboard_all_groups = 'leaderboard_all_groups';
  public static readonly leaderboard_desc = 'leaderboard_desc';
  public static readonly leaderboard_plus_point_title =
    'leaderboard_plus_point_title';
  public static readonly leaderboard_plus_point_desc =
    'leaderboard_plus_point_desc';
  public static readonly leaderboard_plus_point_button =
    'leaderboard_plus_point_button';
  public static readonly leaderboard_profile_button =
    'leaderboard_profile_button';
  public static readonly plus_point_limit_expired_popup_header =
    'plus_point_limit_expired_popup_header';
  public static readonly plus_point_limit_expired_popup_desc =
    'plus_point_limit_expired_popup_desc';
  public static readonly plus_point_limit_expired_popup_button =
    'plus_point_limit_expired_popup_button';
  public static readonly leaderboard_see_all = 'leaderboard_see_all';
  public static readonly empty_leaderboard_header = 'empty_leaderboard_header';
  public static readonly empty_leaderboard_desc = 'empty_leaderboard_desc';

  public static readonly header_homepage_tab = 'header_homepage_tab';
  public static readonly header_contact_tab = 'header_contact_tab';
  public static readonly header_faq_tab = 'header_faq_tab';
  public static readonly header_login_button = 'header_login_button';
  public static readonly header_signup_button = 'header_signup_button';

  public static readonly duyurular_title = 'duyurular_title';
  public static readonly duyurular_desc = 'duyurular_desc';
  public static readonly duyurular_search_placeholder =
    'duyurular_search_placeholder';

  public static readonly survey_header = 'survey_header';
  public static readonly survey_desc = 'survey_desc';
  public static readonly poll_button_back = 'poll_button_back';
  public static readonly poll_button_next = 'poll_button_next';
  public static readonly poll_button_home = 'poll_button_home';
  public static readonly poll_title = 'poll_title';
  public static readonly poll_desc = 'poll_desc';
  public static readonly quiz_title = 'quiz_title';

  public static readonly poll_leaderboard_title = 'poll_leaderboard_title';
  public static readonly poll_leaderboard_button = 'poll_leaderboard_button';
  public static readonly poll_result_page_title = 'poll_result_page_title';
  public static readonly poll_result_page_desc = 'poll_result_page_desc';
  public static readonly poll_result_page_no_more_quiz_title =
    'poll_result_page_no_more_quiz_title';
  public static readonly poll_result_page_no_more_quiz_desc =
    'poll_result_page_no_more_quiz_desc';

  public static readonly poll_result_page_point_button =
    'poll_result_page_point_button';
  public static readonly poll_result_page_ok_button =
    'poll_result_page_ok_button';
  public static readonly pol_result_continue_button =
    'pol_result_continue_button';
  public static readonly pol_result_point_button = 'pol_result_point_button';

  public static readonly profile_header_text = 'profile_header_text';
  public static readonly profile_progress_text = 'profile_progress_text';
  public static readonly profile_finished_percent = 'profile_finished_percent';
  public static readonly profile_edit_button = 'profile_edit_button';

  public static readonly landing_header = 'landing_header';
  public static readonly landing_desc = 'landing_desc';
  public static readonly landing_button = 'landing_button';

  public static readonly landing_benefits_1_title = 'landing_benefits_1_title';
  public static readonly landing_benefits_2_title = 'landing_benefits_2_title';
  public static readonly landing_benefits_3_title = 'landing_benefits_3_title';
  public static readonly landing_benefits_1_desc = 'landing_benefits_1_desc';
  public static readonly landing_benefits_2_desc = 'landing_benefits_2_desc';
  public static readonly landing_benefits_3_desc = 'landing_benefits_3_desc';

  public static readonly landing_card_header = 'landing_card_header';
  public static readonly landing_card_title = 'landing_card_title';
  public static readonly landing_card_desc = 'landing_card_desc';
  public static readonly landing_card_button = 'landing_card_button';

  public static readonly landing_clubs_title = 'landing_clubs_title';
  public static readonly landing_clubs_desc = 'landing_clubs_desc';

  public static readonly landing_team_json = 'landing_team_json';

  public static readonly landing_subscription_title =
    'landing_subscription_title';
  public static readonly landing_subscription_desc =
    'landing_subscription_desc';
  public static readonly landing_subscription_standart_title =
    'landing_subscription_standart_title';
  public static readonly landing_subscription_standart_desc =
    'landing_subscription_standart_desc';
  public static readonly landing_subscription_standart_price =
    'landing_subscription_standart_price';
  public static readonly landing_subscription_standart_button =
    'landing_subscription_standart_button';

  public static readonly landing_subscription_standart_benefit_1 =
    'landing_subscription_standart_benefit_1';
  public static readonly landing_subscription_standart_benefit_2 =
    'landing_subscription_standart_benefit_2';
  public static readonly landing_subscription_standart_benefit_3 =
    'landing_subscription_standart_benefit_3';

  public static readonly upgrade_page_header = 'upgrade_page_header';
  public static readonly upgrade_page_desc = 'upgrade_page_desc';
  public static readonly upgrade_page_cancel_button =
    'upgrade_page_cancel_button';
  public static readonly upgrade_page_upgrade_button =
    'upgrade_page_upgrade_button';
  public static readonly upgrade_page_current_button =
    'upgrade_page_current_button';

  public static readonly landing_sports_title = 'landing_sports_title';
  public static readonly landing_sports_desc = 'landing_sports_desc';
  public static readonly landing_sports_button = 'landing_sports_button';

  public static readonly landing_faq_header = 'landing_faq_header';
  public static readonly landing_faq_title = 'landing_faq_title';
  public static readonly landing_faq_desc = 'landing_faq_desc';

  public static readonly landing_cookie_popup_desc =
    'landing_cookie_popup_desc';
  public static readonly landing_cookie_popup_accept_button =
    'landing_cookie_popup_accept_button';
  public static readonly landing_cookie_popup_read_more_button =
    'landing_cookie_popup_read_more_button';

  public static readonly profile_has_subscription_header =
    'profile_has_subscription_header';
  public static readonly profile_has_subscription_desc =
    'profile_has_subscription_desc';
  public static readonly profile_no_subscription_header =
    'profile_no_subscription_header';
  public static readonly profile_no_subscription_title =
    'profile_no_subscription_title';
  public static readonly profile_no_subscription_desc =
    'profile_no_subscription_desc';
  public static readonly profile_no_subscription_button =
    'profile_no_subscription_button';

  public static readonly profile_survey_not_started_header =
    'profile_survey_not_started_header';
  public static readonly profile_survey_not_started_button =
    'profile_survey_not_started_button';
  public static readonly profile_survey_not_finished_header =
    'profile_survey_not_finished_header';
  public static readonly profile_survey_not_finished_button =
    'profile_survey_not_finished_button';
  public static readonly profile_survey_finished_header =
    'profile_survey_finished_header';
  public static readonly profile_survey_finished_button =
    'profile_survey_finished_button';

  public static readonly profile_events_header = 'profile_events_header';
  public static readonly profile_events_button = 'profile_events_button';

  public static readonly profile_social_media_header =
    'profile_social_media_header';
  public static readonly profile_social_media_desc =
    'profile_social_media_desc';

  public static readonly profile_edit_pick_an_avatar_button =
    'profile_edit_pick_an_avatar_button';
  public static readonly profile_edit_avatar_change_button =
    'profile_edit_avatar_change_button';
  public static readonly profile_edit_avatar_delete_button =
    'profile_edit_avatar_delete_button';
  public static readonly profile_edit_save_button = 'profile_edit_save_button';
  public static readonly profile_permission_save_button =
    'profile_permission_save_button';
  public static readonly profile_edit_cancel_button =
    'profile_edit_cancel_button';
  public static readonly profile_edit_title = 'profile_edit_title';
  public static readonly profile_msisdn_title = 'profile_msisdn_title';
  public static readonly profile_edit_permissions_button =
    'profile_edit_permissions_button';
  public static readonly profile_edit_delete_my_account_button =
    'profile_edit_delete_my_account_button';
  public static readonly profile_edit_name_title = 'profile_edit_name_title';
  public static readonly profile_edit_name_text = 'profile_edit_name_text';
  public static readonly profile_edit_surname_title =
    'profile_edit_surname_title';
  public static readonly profile_edit_surname_text =
    'profile_edit_surname_text';
  public static readonly profile_edit_birthdate_title =
    'profile_edit_birthdate_title';
  public static readonly profile_edit_birthdate_text =
    'profile_edit_birthdate_text';
  public static readonly profile_edit_gender_title =
    'profile_edit_gender_title';
  public static readonly profile_edit_gender_text = 'profile_edit_gender_text';
  public static readonly profile_edit_tckn_text = 'profile_edit_tckn_text';
  public static readonly profile_edit_no_tckn_title =
    'profile_edit_no_tckn_title';
  public static readonly profile_edit_no_tckn_text =
    'profile_edit_no_tckn_text';
  public static readonly profile_edit_pasaport_no_text =
    'profile_edit_pasaport_no_text';
  public static readonly profile_edit_country_title =
    'profile_edit_country_title';
  public static readonly profile_edit_country_text =
    'profile_edit_country_text';
  public static readonly profile_edit_city_title = 'profile_edit_city_title';
  public static readonly profile_edit_city_text = 'profile_edit_city_text';
  public static readonly profile_edit_street_title =
    'profile_edit_street_title';
  public static readonly profile_edit_street_text = 'profile_edit_street_text';
  public static readonly profile_edit_profession_title =
    'profile_edit_profession_title';
  public static readonly profile_edit_profession_text =
    'profile_edit_profession_text';
  public static readonly profile_edit_hobby_title = 'profile_edit_hobby_title';
  public static readonly profile_edit_hobby_text = 'profile_edit_hobby_text';
  public static readonly profile_edit_mail_title = 'profile_edit_mail_title';
  public static readonly profile_edit_mail_text = 'profile_edit_mail_text';
  public static readonly profile_edit_tribun_grubu_title =
    'profile_edit_tribun_grubu_title';
  public static readonly profile_edit_tribun_title =
    'profile_edit_tribun_title';
  public static readonly profile_edit_tribun_placeholder =
    'profile_edit_tribun_placeholder';
  public static readonly profile_edit_tribun_grubu_placeholder =
    'profile_edit_tribun_grubu_placeholder';
  public static readonly profile_edit_place_title = 'profile_edit_place_title';
  public static readonly profile_edit_place_placeholder =
    'profile_edit_place_placeholder';

  public static readonly profile_edit_avatar_edit_button =
    'profile_edit_avatar_edit_button';
  public static readonly profile_edit_avatar_cancel_button =
    'profile_edit_avatar_cancel_button';

  public static readonly profile_edit_reminder_text =
    'profile_edit_reminder_text';
  public static readonly profile_edit_mernis_warning_text =
    'profile_edit_mernis_warning_text';

  public static readonly profile_edit_permissions_popup_title =
    'profile_edit_permissions_popup_title';
  public static readonly profile_edit_permissions_popup_etk_title =
    'profile_edit_permissions_popup_etk_title';
  public static readonly profile_edit_permissions_popup_etk_desc =
    'profile_edit_permissions_popup_etk_desc';
  public static readonly profile_edit_permissions_popup_acikriza_title =
    'profile_edit_permissions_popup_acikriza_title';
  public static readonly profile_edit_permissions_popup_acikriza_desc =
    'profile_edit_permissions_popup_acikriza_desc';
  public static readonly profile_edit_permissions_popup_agreement_title =
    'profile_edit_permissions_popup_agreement_title';
  public static readonly profile_edit_permissions_popup_agreement_desc =
    'profile_edit_permissions_popup_agreement_desc';
  public static readonly profile_edit_permissions_popup_user_agreement_title =
    'profile_edit_permissions_popup_user_agreement_title';
  public static readonly profile_edit_permissions_popup_user_agreement_desc =
    'profile_edit_permissions_popup_user_agreement_desc';
  public static readonly profile_edit_permissions_popup_kvkk_title =
    'profile_edit_permissions_popup_kvkk_title';
  public static readonly profile_edit_permissions_popup_kvkk_desc =
    'profile_edit_permissions_popup_kvkk_desc';

  public static readonly profile_agreements = 'profile_agreements';
  public static readonly profile_delete_account = 'profile_delete_account';
  public static readonly profile_edit_cancel_subscription_button =
    'profile_edit_cancel_subscription_button';
  public static readonly profile_edit_cancel_subscription_popup_title =
    'profile_edit_cancel_subscription_popup_title';
  public static readonly profile_edit_cancel_subscription_popup_desc =
    'profile_edit_cancel_subscription_popup_desc';
  public static readonly profile_edit_cancel_subscription_popup_accept_button =
    'profile_edit_cancel_subscription_popup_accept_button';
  public static readonly profile_edit_cancel_subscription_popup_cancel_button =
    'profile_edit_cancel_subscription_popup_cancel_button';
  public static readonly profile_edit_cancelled_subscription_popup_title =
    'profile_edit_cancelled_subscription_popup_title';
  public static readonly profile_edit_cancelled_subscription_popup_desc =
    'profile_edit_cancelled_subscription_popup_desc';
  public static readonly profile_edit_cancelled_subscription_popup_button =
    'profile_edit_cancelled_subscription_popup_button';
  public static readonly profile_edit_delete_account_title =
    'profile_edit_delete_account_title';
  public static readonly profile_edit_delete_account_desc =
    'profile_edit_delete_account_desc';
  public static readonly profile_edit_delete_account_button =
    'profile_edit_delete_account_button';
  public static readonly delete_account_have_sub_title =
    'delete_account_have_sub_title';
  public static readonly delete_account_have_sub_desc =
    'delete_account_have_sub_desc';
  public static readonly delete_account_have_sub_button =
    'delete_account_have_sub_button';
  public static readonly delete_account_are_you_sure_title =
    'delete_account_are_you_sure_title';
  public static readonly delete_account_are_you_sure_desc =
    'delete_account_are_you_sure_desc';
  public static readonly delete_account_are_you_sure_button_close =
    'delete_account_are_you_sure_button_close';
  public static readonly delete_account_are_you_sure_button_delete =
    'delete_account_are_you_sure_button_delete';

  public static readonly profile_currentplan_finish_date_title =
    'profile_currentplan_finish_date_title';
  public static readonly profile_currentplan_update_button =
    'profile_currentplan_update_button';
  public static readonly profile_currentplan_renew_date_title =
    'profile_currentplan_renew_date_title';
  public static readonly profile_current_plan_features =
    'profile_current_plan_features';
  public static readonly profile_cancel_success_popup_title =
    'profile_cancel_success_popup_title';
  public static readonly profile_cancel_success_popup_desc =
    'profile_cancel_success_popup_desc';
  public static readonly profile_cancel_subs_popup_title =
    'profile_cancel_subs_popup_title';
  public static readonly profile_cancel_subs_popup_desc =
    'profile_cancel_subs_popup_desc';
  public static readonly profile_cancel_subs_popup_accept_button =
    'profile_cancel_subs_popup_accept_button';
  public static readonly profile_cancel_subs_popup_cancel_title =
    'profile_cancel_subs_popup_cancel_title';
  public static readonly profile_canceled_subs_edit_header =
    'profile_canceled_subs_edit_header';
  public static readonly profile_canceled_subs_edit_desc =
    'profile_canceled_subs_edit_desc';
  public static readonly profile_canceled_subs_button =
    'profile_canceled_subs_button';

  public static readonly profile_rumuz_title = 'profile_rumuz_title';
  public static readonly profile_rumuz = 'profile_rumuz';
  public static readonly profile_subscription_warning =
    'profile_subscription_warning';
  public static readonly profile_subscription_warning_button =
    'profile_subscription_warning_button';
  public static readonly menu_choise_group = 'menu_choise_group';
  public static readonly logout_popup_title = 'logout_popup_title';
  public static readonly logout_popup_desc = 'logout_popup_desc';
  public static readonly logout_popup_exit_button = 'logout_popup_exit_button';
  public static readonly logout_popup_cancel_button =
    'logout_popup_cancel_button';

  public static readonly wheel_header = 'wheel_header';
  public static readonly wheel_desc = 'wheel_desc';
  public static readonly wheel_turned_title = 'wheel_turned_title';
  public static readonly wheel_not_subscribed_title =
    'wheel_not_subscribed_title';
  public static readonly wheel_not_subscribed_desc =
    'wheel_not_subscribed_desc';
  public static readonly wheel_sub_button = 'wheel_sub_button';

  public static readonly wheel_not_turned_title = 'wheel_not_turned_title';
  public static readonly wheel_not_turned_desc = 'wheel_not_turned_desc';

  public static readonly active_benefits_title = 'active_benefits_title';
  public static readonly passive_benefits_title = 'passive_benefits_title';
  public static readonly partner_benefits_title = 'partner_benefits_title';

  public static readonly benefits_title = 'benefits_title';
  public static readonly benefits_desc = 'benefits_desc';
  public static readonly benefits_subscription_text =
    'benefits_subscription_text';
  public static readonly benefits_subscription_button =
    'benefits_subscription_button';

  public static readonly active_benefits_page_banner_title =
    'active_benefits_page_banner_title';
  public static readonly active_benefits_page_banner_desc =
    'active_benefits_page_banner_desc';
  public static readonly passive_benefits_page_banner_title =
    'passive_benefits_page_banner_title';
  public static readonly passive_benefits_page_banner_desc =
    'passive_benefits_page_banner_desc';
  public static readonly partner_benefits_page_banner_title =
    'partner_benefits_page_banner_title';
  public static readonly partner_benefits_page_banner_desc =
    'partner_benefits_page_banner_desc';

  public static readonly invite_page_msisdn_placeholder =
    'invite_page_msisdn_placeholder';
  public static readonly invite_page_button = 'invite_page_button';
  public static readonly invite_page_title = 'invite_page_title';
  public static readonly invite_page_desc = 'invite_page_desc';
  public static readonly invite_page_success_popup_title =
    'invite_page_success_popup_title';
  public static readonly invite_page_success_popup_desc =
    'invite_page_success_popup_desc';
  public static readonly invite_page_success_popup_button =
    'invite_page_success_popup_button';
  public static readonly invite_page_fail_popup_title =
    'invite_page_fail_popup_title';
  public static readonly invite_page_fail_popup_desc =
    'invite_page_fail_popup_desc';
  public static readonly invite_page_fail_popup_button =
    'invite_page_fail_popup_button';
  public static readonly invite_page_already_invited_popup_title =
    'invite_page_already_invited_popup_title';
  public static readonly invite_page_already_invited_popup_desc =
    'invite_page_already_invited_popup_desc';

  public static readonly plus_point_main_page_header =
    'plus_point_main_page_header';
  public static readonly plus_point_main_page_desc =
    'plus_point_main_page_desc';
  public static readonly plus_point_balance = 'plus_point_balance';
  public static readonly plus_point_header = 'plus_point_header';
  public static readonly plus_point_description = 'plus_point_description';
  public static readonly plus_point_button = 'plus_point_button';
  public static readonly plus_point_earn_text = 'plus_point_earn_text';
  public static readonly plus_point_list_header = 'plus_point_list_header';
  public static readonly plus_point_list_desc = 'plus_point_list_desc';
  public static readonly plus_point_onetime_tasks = 'plus_point_onetime_tasks';
  public static readonly plus_point_onetime_task_done =
    'plus_point_onetime_task_done';
  public static readonly plus_point_onetime_task_one =
    'plus_point_onetime_task_one';
  public static readonly plus_point_onetime_task_one_total =
    'plus_point_onetime_task_one_total';
  public static readonly plus_point_onetime_task_two =
    'plus_point_onetime_task_two';
  public static readonly plus_point_onetime_task_two_total =
    'plus_point_onetime_task_two_total';
  public static readonly plus_point_onetime_task_three =
    'plus_point_onetime_task_three';
  public static readonly plus_point_onetime_task_three_total =
    'plus_point_onetime_task_three_total';
  public static readonly plus_point_onetime_task_four =
    'plus_point_onetime_task_four';
  public static readonly plus_point_onetime_task_four_total =
    'plus_point_onetime_task_four_total';
  public static readonly plus_point_constant_tasks =
    'plus_point_constant_tasks';
  public static readonly plus_point_constant_task_one =
    'plus_point_constant_task_one';
  public static readonly plus_point_constant_task_one_total =
    'plus_point_constant_task_one_total';
  public static readonly plus_point_constant_task_two =
    'plus_point_constant_task_two';
  public static readonly plus_point_constant_task_two_total =
    'plus_point_constant_task_two_total';
  public static readonly plus_point_constant_task_info =
    'plus_point_constant_task_info';

  public static readonly plus_point_subscription_header =
    'plus_point_subscription_header';
  public static readonly plus_point_subscription_description =
    'plus_point_subscription_description';
  public static readonly plus_point_profile_header =
    'plus_point_profile_header';
  public static readonly plus_point_profile_description =
    'plus_point_profile_description';
  public static readonly plus_point_invitation_header =
    'plus_point_invitation_header';
  public static readonly plus_point_invitation_description =
    'plus_point_invitation_description';
  public static readonly plus_point_whell_header = 'plus_point_whell_header';
  public static readonly plus_point_wheel_description =
    'plus_point_wheel_description';
  public static readonly plus_point_trivia_header = 'plus_point_trivia_header';
  public static readonly plus_point_trivia_description =
    'plus_point_trivia_description';
  public static readonly plus_point_survey_header = 'plus_point_survey_header';
  public static readonly plus_point_page_survey_description =
    'plus_point_page_survey_description';

  public static readonly convert_banner_points = 'convert_banner_points';
  public static readonly convert_banner_header = 'convert_banner_header';
  public static readonly convert_banner_button = 'convert_banner_button';
  public static readonly convert_main_page_title = 'convert_main_page_title';
  public static readonly convert_main_page_desc = 'convert_main_page_desc';
  public static readonly convert_main_page_plus_point_text =
    'convert_main_page_plus_point_text';
  public static readonly convert_main_page_wheel_text =
    'convert_main_page_wheel_text';
  public static readonly convert_main_page_share_text =
    'convert_main_page_share_text';

  public static readonly convert_page_header = 'convert_page_header';
  public static readonly convert_page_desc = 'convert_page_desc';
  public static readonly convert_page_points = 'convert_page_points';
  public static readonly convert_popup_title = 'convert_popup_title';
  public static readonly convert_popup_desc = 'convert_popup_desc';
  public static readonly convert_popup_cancel_button =
    'convert_popup_cancel_button';
  public static readonly convert_popup_accept_button =
    'convert_popup_accept_button';
  public static readonly convert_popup_insufficient_balance_title =
    'convert_popup_insufficient_balance_title';
  public static readonly convert_popup_insufficient_balance_desc =
    'convert_popup_insufficient_balance_desc';
  public static readonly convert_popup_insufficient_balance_cancel_button =
    'convert_popup_insufficient_balance_cancel_button';
  public static readonly convert_popup_insufficient_balance_point_button =
    'convert_popup_insufficient_balance_point_button';

  public static readonly prize_popup_code_header = 'prize_popup_code_header';
  public static readonly prize_popup_code_desc = 'prize_popup_code_desc';
  public static readonly prize_popup_code_win_text =
    'prize_popup_code_win_text';
  public static readonly prize_popup_code_reward_title =
    'prize_popup_code_reward_title';
  public static readonly prize_popup_code_copied = 'prize_popup_code_copied';
  public static readonly prize_popup_code_how_to_use_button =
    'prize_popup_code_how_to_use_button';
  public static readonly prize_popup_wallet_header =
    'prize_popup_wallet_header';
  public static readonly prize_popup_wallet_win_text =
    'prize_popup_wallet_win_text';
  public static readonly prize_popup_wallet_desc = 'prize_popup_wallet_desc';
  public static readonly prize_popup_wallet_button =
    'prize_popup_wallet_button';
  public static readonly prize_popup_physical_header =
    'prize_popup_physical_header';
  public static readonly prize_popup_physical_win_text =
    'prize_popup_physical_win_text';
  public static readonly prize_popup_physical_desc =
    'prize_popup_physical_desc';
  public static readonly prize_popup_physical_info_title =
    'prize_popup_physical_info_title';
  public static readonly prize_popup_physical_name_placeholder =
    'prize_popup_physical_name_placeholder';
  public static readonly prize_popup_physical_surname_placeholder =
    'prize_popup_physical_surname_placeholder';
  public static readonly prize_popup_physical_email_placeholder =
    'prize_popup_physical_email_placeholder';
  public static readonly prize_popup_physical_adress_placeholder =
    'prize_popup_physical_adress_placeholder';
  public static readonly prize_popup_physical_send_button =
    'prize_popup_physical_send_button';

  public static readonly how_to_use_popup_header = 'how_to_use_popup_header';
  public static readonly how_to_use_popup_title = 'how_to_use_popup_title';
  public static readonly how_to_use_popup_button = 'how_to_use_popup_button';

  public static readonly token_home_header = 'token_home_header';
  public static readonly token_home_desc = 'token_home_desc';
  public static readonly token_home_current_balance_text =
    'token_home_current_balance_text';
  public static readonly token_succesfull_payment_header =
    'token_succesfull_payment_header';
  public static readonly token_succesfull_payment_desc =
    'token_succesfull_payment_desc';
  public static readonly token_succesfull_paymen_balance_text =
    'token_succesfull_paymen_balance_text';
  public static readonly token_succesfull_paymen_title =
    'token_succesfull_paymen_title';
  public static readonly token_succesfull_paymen_trivia_button =
    'token_succesfull_paymen_trivia_button';
  public static readonly token_unsuccesfull_payment_title =
    'token_unsuccesfull_payment_title';
  public static readonly token_unsuccesfull_payment_desc =
    'token_unsuccesfull_payment_desc';
  public static readonly token_unsuccesfull_payment_home_button =
    'token_unsuccesfull_payment_home_button';
  public static readonly token_unsuccesfull_payment_try_again_button =
    'token_unsuccesfull_payment_try_again_button';

  public static readonly trivia_home_header = 'trivia_home_header';
  public static readonly trivia_home_desc = 'trivia_home_desc';
  public static readonly trivia_home_leaderboard_button =
    'trivia_home_leaderboard_button';

  public static readonly trivia_are_you_sure_free_popup_title =
    'trivia_are_you_sure_free_popup_title';
  public static readonly trivia_are_you_sure_free_popup_desc =
    'trivia_are_you_sure_free_popup_desc';
  public static readonly trivia_are_you_sure_free_popup_button =
    'trivia_are_you_sure_free_popup_button';
  public static readonly trivia_are_you_sure_free_popup_cancel_button =
    'trivia_are_you_sure_free_popup_cancel_button';
  public static readonly trivia_are_you_sure_popup_title =
    'trivia_are_you_sure_popup_title';
  public static readonly trivia_are_you_sure_popup_desc =
    'trivia_are_you_sure_popup_desc';
  public static readonly trivia_are_you_sure_popup_button =
    'trivia_are_you_sure_popup_button';
  public static readonly trivia_are_you_sure_popup_cancel_button =
    'trivia_are_you_sure_popup_cancel_button';
  public static readonly trivia_are_you_sure_dont_ask_again_button =
    'trivia_are_you_sure_dont_ask_again_button';

  public static readonly trivia_free_right_answer_popup_title =
    'trivia_free_right_answer_popup_title';
  public static readonly trivia_free_right_answer_popup_desc =
    'trivia_free_right_answer_popup_desc';
  public static readonly trivia_free_right_answer_popup_text =
    'trivia_free_right_answer_popup_text';
  public static readonly trivia_right_answer_popup_title =
    'trivia_right_answer_popup_title';
  public static readonly trivia_right_answer_popup_desc =
    'trivia_right_answer_popup_desc';
  public static readonly trivia_right_answer_popup_text =
    'trivia_right_answer_popup_text';

  public static readonly trivia_free_wrong_answer_popup_title =
    'trivia_free_wrong_answer_popup_title';
  public static readonly trivia_free_wrong_answer_popup_desc =
    'trivia_free_wrong_answer_popup_desc';
  public static readonly trivia_free_wrong_answer_popup_text =
    'trivia_free_wrong_answer_popup_text';
  public static readonly trivia_wrong_answer_popup_title =
    'trivia_wrong_answer_popup_title';
  public static readonly trivia_wrong_answer_popup_desc =
    'trivia_wrong_answer_popup_desc';
  public static readonly trivia_wrong_answer_popup_text =
    'trivia_wrong_answer_popup_text';

  public static readonly trivia_insufficient_balance_title =
    'trivia_insufficient_balance_title';
  public static readonly trivia_insufficient_balance_desc =
    'trivia_insufficient_balance_desc';
  public static readonly trivia_insufficient_balance_button =
    'trivia_insufficient_balance_button';
  public static readonly trivia_insufficient_balance_cancel_button =
    'trivia_insufficient_balance_cancel_button';
  public static readonly trivia_free_daily_limit_reached_title =
    'trivia_free_daily_limit_reached_title';
  public static readonly trivia_free_daily_limit_reached_desc =
    'trivia_free_daily_limit_reached_desc';
  public static readonly trivia_free_daily_limit_reached_button =
    'trivia_free_daily_limit_reached_button';

  // EVENTS
  public static readonly test_event = 'test_event';
}
